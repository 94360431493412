/* src/Pages/animations.css */
.accordion-enter {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .accordion-enter-active {
    max-height: 1000px; /* Or any max-height you expect */
  }
  
  .accordion-exit {
    max-height: 1000px;
  }
  
  .accordion-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional custom styles if needed */

/* Example styles to ensure dark mode works */
body {
  @apply bg-white text-gray-800;
}

.dark body {
  @apply bg-gray-900 text-white;
}


/* In your global CSS file */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  @media (max-width: 768px) {
    header {
      background-position: center 20%;  
    }
  }
  

  .scheduler-container {
    height: 24rem; /* Adjust this value to fit the modal size */
    overflow: auto; /* Correct syntax */
  }
  
  .scheduler-container .scheduler-table {
    font-size: 0.875rem; /* Tailwind's 'text-sm' */
  }
  
  .scheduler-container .scheduler-content {
    height: 100%;
  }
  
  .scheduler-container .scheduler-header {
    padding: 0.5rem 0.25rem; /* Tailwind's 'py-2 px-1' */
  }
  
  .scheduler-container .scheduler-view {
    margin: 0;
    padding: 0;
  }
  
  .aspect-w-1 {
    aspect-ratio: 1 / 1;
  }
  
  .aspect-h-1 {
    aspect-ratio: 1 / 1;
  }

  
  .newsletter-editor {
  display: flex;
  height: 100vh;
}

.widget-menu {
  width: 20%;
  background-color: #f5f5f5;
  padding: 20px;
  overflow-y: auto;
}

.editor-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.editor-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #e0e0e0;
}

.device-switcher button,
.editor-actions button {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.device-switcher button svg,
.editor-actions button svg {
  width: 24px;
  height: 24px;
}

.editor-body {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.editor-sections {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

.section {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.section-header {
  display: flex;
  justify-content: space-between;
}

.section-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.live-preview {
  width: 50%;
  padding: 20px;
  background-color: #fafafa;
}

.live-preview.desktop {
  width: 50%;
}

.live-preview.tablet {
  width: 768px;
  margin: 0 auto;
}

.live-preview.mobile {
  width: 375px;
  margin: 0 auto;
}

.modal {
  width: 80%;
  max-width: 800px;
  margin: 100px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
